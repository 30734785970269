<template>
  <router-view />
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
// Import Main styles for this application
body {
  font-family: 'DM Sans', sans-serif;
}

:root {
  --logo: #834aff; //this is for all buttons and main color
  --white: #ffffff;
  --grey1: #efefef;
  --grey2: #686868;
  --grey3: #969697;
  --black3: #171717;
  --black2: #212123;
  --black1: #131315;
  --black: #000000;
  --green: #61c191;
  --yellow: #ffc94f;
  --red: #ff5c50;
}
@import 'styles/style';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.home-text-1,
.chat-text-1 {
  font-size: clamp(1.5rem, 5vw, 0.75rem);
}

.home-text-2,
.chat-text-2 {
  font-size: clamp(1rem, 3.3vw, 0.5rem);
}

.home-text-3,
.chat-text-3 {
  font-size: clamp(0.75rem, 2.5vw, 0.4rem);
}

.overlay {
  overflow: overlay;
}

.c-pointer {
  cursor: pointer;
}

.aspect-square {
  aspect-ratio: 1/1;
}
</style>
