<template>
  <div
    class="d-flex flex-column p-4 app-sidebar vh-100 justify-content-between"
    :class="{
      'full-nav': fullNav,
      'gf-expand': !fullNav && expanded,
    }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="d-flex flex-column gap-5">
      <div
        class="d-flex justify-content-between align-items-center brand-container"
      >
        <img
          src="@/assets/brand/goflurry.png"
          alt="brand-logo"
          v-if="expanded || fullNav"
        />
        <img src="@/assets/brand/go.png" alt="brand-logo" v-else />
        <i
          class="fa-solid fa-xmark fa-2xl"
          @click="closeNav"
          v-if="fullNav"
        ></i>
      </div>
      <div class="nav-items">
        <div
          v-for="(item, index) in navItems"
          :key="index"
          class="d-flex align-items-center n-item"
          :class="{
            'active-nav': activeNavItem.search(item.to) !== -1,
          }"
          @click="visitLink(item.to)"
        >
          <i class="fa-solid fa-xl" :class="item.icon"></i>
          <div class="gf-nav-text">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center n-item bg-danger" @click="logout">
      <i class="fa-solid fa-right-from-bracket fa-xl"></i>
      <div class="gf-nav-text">Logout</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'

import navItems from '@/_nav'
import util from '@/util'

export default {
  name: 'AppSidebar',
  components: {
    // AppSidebarNav,
  },
  props: {
    fullNav: {
      type: Boolean,
    },
  },
  setup() {
    const route = useRoute()
    const expanded = ref(false)
    const activeNavItem = ref(route.name)
    return {
      ...mapState('common', ['sidebarUnfoldable', 'sidebarVisible']),
      expanded,
      activeNavItem,
      navItems,
    }
  },
  computed: {
    ...mapGetters('login', {
      isLoggedIn: 'isLoggedIn',
    }),
    showHeader() {
      // STATE is not persisting, it reset on reload of page.
      return this.isLoggedIn || localStorage.getItem('login')
    },
  },
  methods: {
    toggleUnfoldable() {
      console.log(this.$store.state.sidebarVisible)
      this.$store.commit('common/toggleUnfoldable') // Use the sidebar module's mutation
    },
    setSidebarVisible(event) {
      this.$store.commit('common/updateSidebarVisible', event) // Use the sidebar module's mutation
    },
    handleMouseEnter() {
      this.expanded = true
    },
    handleMouseLeave() {
      this.expanded = false
    },
    closeNav() {
      this.$emit('closeNav')
    },
    visitLink(val) {
      this.activeNavItem = val
      this.$router.push({ name: val })
      this.closeNav()
    },
    logout() {
      util.logout()
    },
  },
}
</script>

<style>
.app-sidebar {
  width: 7rem;
  min-height: 100vh;
  background-color: var(--black1);
  border-right: 1px solid rgb(255, 255, 255, 0.2);
  z-index: 2;

  transition: all 0.3s;
}

.brand-container {
  display: flex;
}

.brand-container img {
  object-fit: cover;
  height: 3rem;
}

.brand-container i {
  color: var(--logo);
  cursor: pointer;
}

.nav-items {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  /* transition: all 0.6s ease-in-out; */
}

.n-item {
  cursor: pointer;
  padding: 1rem 1.2rem;
  border-radius: 6px;
  color: white;
  font-size: 1rem;
  display: flex;
  gap: 1rem;
}

.n-item i {
  width: 2rem;
}

.sidebar {
  background-color: var(--black1);
}

.brand {
  color: var(--white);
  font-size: 1.5rem;
}

.brand-position {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.dark {
  background-color: var(--black1);
  z-index: 1;
}

.nav-item {
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.active-nav {
  background-color: #212123;
}

.gf-expand {
  width: clamp(7rem, 25vw, 15rem);
}

.app-sidebar .gf-nav-text {
  opacity: 0;
  transition: all 0.3s;
}

.gf-expand .gf-nav-text {
  opacity: 1;
  font-size: 1rem;
  font-weight: 600;
  width: 0%;
}

.full-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.full-nav .gf-nav-text {
  opacity: 1;
}
</style>
