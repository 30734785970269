import axios from 'axios'
import env from '@/env.js'

export const apiClient = axios.create({
  baseURL: env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
})

export const apiClientSecure = axios.create({
  baseURL: env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
})

apiClientSecure.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      config.headers.Authorization = accessToken
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export const uploadAxios = axios.create({
  baseURL: env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: localStorage.getItem('accessToken'),
  },
  timeout: 120000,
})
