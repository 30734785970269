<template>
  <div class="vw-100 vh-100 gf-screen">
    <div class="h-100 w-100 d-flex gf-container text-white overflow-hidden">
      <AppSidebar v-if="showSideBar" :fullNav="fullNav" @closeNav="closeNav" />
      <div class="mh-100 w-100 d-flex flex-column justify-content-between">
        <RouterView @showNav="showNav" />
        <div class="mobile-menu" v-if="showMobileMenu">
          <MobileMenu />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import { decodeCredential } from 'vue3-google-login'
import { initializeTwilioClient } from '@/services/chatClient.js'

export default {
  name: 'DefaultLayout',
  components: {
    AppSidebar,
    MobileMenu,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      fullNav: false,
      notShow: ['/login', '/onboard'],
    }
  },
  computed: {
    showSideBar() {
      if (
        (this.windowWidth > 850 || this.fullNav) &&
        !this.notShow.includes(this.$route.path)
      ) {
        return true
      }
      return false
    },
    showMobileMenu() {
      // return true
      const isMobile = this.windowWidth <= 850
      const current = this.$route.fullPath
      const pattern = /^\/chat\?p=/
      const particular = pattern.test(current)
      if (isMobile && !this.notShow.includes(current) && !particular) {
        return true
      }
      return false
    },
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    showNav() {
      this.fullNav = true
    },
    closeNav() {
      this.fullNav = false
    },
    callback(response) {
      const userData = decodeCredential(response.credential)
      console.log('Handle the userData', userData)
    },
  },
  mounted() {
    console.log('mounted default')
    if (localStorage.getItem('login')) {
      initializeTwilioClient()
    }
    window.addEventListener('resize', this.handleResize)
  },
}
</script>

<style scoped>
.gf-screen {
  background-color: var(--black2);
}

.mobile-menu {
  background-color: var(--black);
  color: var(--white);
  border-radius: 20px 20px 0 0;
}
</style>
