import Vuex from 'vuex'
import loginModule from './login'
import searchModule from './search'
import commonModule from './common'

const store = new Vuex.Store({
  modules: {
    login: loginModule,
    serch: searchModule,
    common: commonModule,
  },
})

export default store
