const state = {
  selectedAccount: 'Instagram',
  audienceFilter: {},
  influencerFilter: {},
}

const getters = {
  selectedAccount: (state) => state.selectedAccount,
  audienceFilter: (state) => state.audienceFilter,
  influencerFilter: (state) => state.influencerFilter,
}

const mutations = {
  setSelectedAccount(state, payload) {
    state.selectedAccount = payload.value
  },
  setAudienceFilter(state, payload) {
    state.audienceFilter = payload.value
  },
  setInfluencerFilter(state, payload) {
    state.influencerFilter = payload.value
  },
}

const actions = {
  setSelectedAccount({ commit, data }) {
    commit('setSelectedAccount', data)
  },
}

const searchModule = {
  namespaced: true, // Add this line to enable namespacing
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
}

export default searchModule
