import { Client as ConversationsClient } from '@twilio/conversations'
import twilioServices from './twilio'

let conversationsClientInstance = null

async function getToken(email) {
  try {
    const response = await twilioServices.getTwilioAccessToken(email)
    return response.data.token
  } catch (error) {
    console.error('Error getting token', error)
    return null
  }
}

export async function initializeTwilioClient() {
  try {
    console.log('initializeTwilioClient', conversationsClientInstance)
    if (!conversationsClientInstance) {
      await createNewClient()
    }
  } catch (error) {
    console.error('Error initializeTwilioClient', error)
  }
}

export async function getConversationsClient() {
  if (!conversationsClientInstance) {
    await initializeTwilioClient()
  }
  return conversationsClientInstance
}

export async function createNewClient() {
  try {
    let email = localStorage.getItem('email')
    const token = await getToken(email)
    if (token) {
      conversationsClientInstance = new ConversationsClient(token) //await ConversationsClient.create(token)
      console.log('initialized conversationsClientInstance')
      return conversationsClientInstance
    }
  } catch (error) {
    console.error('Error createNewClient', error)
  }
}
