import { uploadAxios, apiClientSecure } from './client'

export default {
  uploadImage(formData) {
    return uploadAxios.post('/image/upload', formData)
  },
  createEvent(form) {
    return apiClientSecure.post('/event', { form: form })
  },
  getEventAll() {
    return apiClientSecure.get('/event/all')
  },
  getNotification() {
    return apiClientSecure.get('/notification')
  },
  getLead() {
    return apiClientSecure.get('/lead')
  },
  getAllBrand() {
    return apiClientSecure.get('/brand/all')
  },
  getBrand(id) {
    return apiClientSecure.get('/brand?id=' + id)
  },
  updateNotifChannel(form) {
    return apiClientSecure.post('/notif/channel', { form: form })
  },
}
