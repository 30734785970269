const state = {
  sidebarUnfoldable: true,
  sidebarVisible: true,
}

const mutations = {
  toggleUnfoldable(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  updateSidebarVisible(state, value) {
    state.sidebarVisible = value
  },
}

const commonModule = {
  namespaced: true, // Add this line to enable namespacing
  state: state,
  mutations: mutations,
}

export default commonModule
