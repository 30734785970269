<template>
  <div class="mobile-menu-list">
    <audio
      ref="notificationSound"
      :src="require('@/assets/sound/notif1.mp3')"
    ></audio>
    <div
      v-for="(item, index) in menuItem"
      :key="index"
      class="mobile-menu-item"
      @click="clickNav(item)"
    >
      <template v-if="item.route === activeRoute">
        <Icon :icon="item.activeIcon" />
        <div class="w-100 active-indicator"></div>
      </template>
      <Icon v-else :icon="item.icon" />
      <a v-if="getLive(item.id)" class="notification-badge"></a>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue'
import { getConversationsClient } from '@/services/chatClient.js'
import trumpetSfx from '@/assets/sound/notif1.mp3'
import twilioServices from '@/services/twilio.js'
import utilServices from '@/services/util'

export default {
  name: 'MobileMenu',
  components: {
    Icon,
  },
  setup() {
    const audio = new Audio(trumpetSfx)
    const handleClick = () => {
      audio.play()
    }
    return { audio, handleClick }
  },
  data() {
    return {
      unreadNotif: false,
      menuItem: [
        {
          id: 1,
          icon: 'ph:house-bold',
          activeIcon: 'ph-house-fill',
          route: '/home',
        },
        {
          id: 2,
          icon: 'teenyicons:message-outline',
          activeIcon: 'teenyicons:message-solid',
          route: '/chat',
        },
        {
          id: 3,
          icon: 'ph:bell-bold',
          activeIcon: 'ph:bell-fill',
          route: '/notification',
        },
        {
          id: 4,
          icon: 'ph:user-bold',
          activeIcon: 'ph:user-fill',
          route: '/profile',
        },
      ],
      activeConversationListener: null,
      username: '',
      total: 0,
    }
  },
  computed: {
    activeRoute() {
      return this.$route.path
    },
  },
  mounted() {
    this.username = localStorage.getItem('username')
    this.getNotif()
  },
  methods: {
    clickNav(item) {
      switch (item.id) {
        case 3:
          this.sendReceived()
          break
      }
      this.$router.push(item.route)
    },
    getNotif: async function () {
      try {
        const notifChannel = 'notif-' + this.username
        const conversationsClient = await getConversationsClient()
        const conversation =
          await conversationsClient.getConversationByUniqueName(notifChannel)
        this.activeConversation = conversation
        this.total = conversation.lastMessage?.index
        this.getMessages()
        if (conversation) {
          this.activeConversationListener = conversation.on(
            'messageAdded',
            (message) => {
              if (message.author === 'admin') {
                this.unreadNotif = true
              } else {
                this.unreadNotif = false
              }
            },
          )
        }
      } catch (error) {
        console.log('notif-' + this.username, error)
        this.createNotifChannel()
      }
    },
    createNotifChannel() {
      let form = {
        name: 'notif-' + this.username,
        userIdentity: this.username,
      }
      twilioServices
        .createConversation(form)
        .then((response) => {
          console.log('createConversation', response.data.conversation)
          let form = {
            notifSid: response.data.conversation.sid,
          }
          this.updateNotifChannel(form)
          this.getNotif()
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    updateNotifChannel(form) {
      utilServices
        .updateNotifChannel(form)
        .then((response) => {
          console.log('response', response.data)
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    getLive(id) {
      switch (id) {
        case 3:
          return this.unreadNotif
        default:
          return false
      }
    },
    getMessages: async function () {
      try {
        const response = await this.activeConversation.getMessages(
          10,
          this.total,
        )
        const messages = response.items.sort((a, b) => {
          return b.dateCreated - a.dateCreated
        })
        if (messages.length > 0) {
          if (messages[0].author === 'admin') {
            this.unreadNotif = true
          }
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    sendReceived: async function () {
      try {
        const notifChannel = 'notif-' + this.username
        const conversationsClient = await getConversationsClient()
        const conversation =
          await conversationsClient.getConversationByUniqueName(notifChannel)
        const message = await conversation.prepareMessage()
        message.setBody('Received')
        await message.build().send()
      } catch (error) {
        console.error('Error sending messages:', error)
      }
    },
  },
}
</script>

<style scoped>
.mobile-menu-list {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
}
.mobile-menu-item {
  font-size: 24px;
  cursor: pointer;
  position: relative;
}
.active-indicator {
  height: 3px;
  background-color: #834aff;
  border-radius: 1.5px;
}

.notification-badge {
  position: absolute;
  top: 0;
  left: 1;
  width: 15px;
  height: 15px;
  background-color: var(--red);
  border-radius: 50%;
  content: '';
}
</style>
