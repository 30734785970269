export default [
  {
    name: 'Home',
    to: 'Home',
    icon: 'fa-home',
  },
  {
    name: 'Message',
    to: 'Chat',
    icon: 'fa-message',
  },
  {
    name: 'Notifications',
    to: 'Notification',
    icon: 'fa-bell',
  },
  {
    name: 'Profile',
    to: 'Profile',
    icon: 'fa-user',
  },
]
