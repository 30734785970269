import { apiClientSecure } from './client'

export default {
  getTwilioAccessToken() {
    return apiClientSecure.get('/twilio/token')
  },
  getInviteConversation() {
    return apiClientSecure.get('/twilio/conversation/all')
  },
  addToConversation(form) {
    return apiClientSecure.post('/twilio/conversation/add', { form: form })
  },
  createConversation(form) {
    return apiClientSecure.post('/twilio/conversation', { form: form })
  },
}
