function isEmptyObject(obj) {
  return obj === null || Object.keys(obj).length === 0
}

function convertTo2DArray(array1D, rows, columns) {
  const array2D = []
  let currentIndex = 0

  for (let i = 0; i < rows; i++) {
    const row = []
    for (let j = 0; j < columns; j++) {
      if (currentIndex < array1D.length) {
        row.push(array1D[currentIndex])
        currentIndex++
      } else {
        row.push(null) // Or any other default value for incomplete rows/columns
      }
    }
    array2D.push(row)
  }
  return array2D
}

function fixNumberToDecimalPlaces(value, pos = 2) {
  if (typeof value === 'number') {
    if (value == 0) {
      return '0'
    }
    return value.toFixed(pos)
  }
  return value
}

function extractKeysAndValues(array, key) {
  const extractedValues = array.map((item) => item[key])
  return extractedValues
}

function validateURL(url, type) {
  const urlRegexMap = {
    instareel: /^https?:\/\/(?:www\.)?instagram\.com\/reel\/[A-Za-z0-9_-]+\/?$/,
    instapost: /^https?:\/\/(?:www\.)?instagram\.com\/p\/[A-Za-z0-9_-]+\/?$/,
    ytvideo: /^https?:\/\/(?:www\.)?youtube\.com\/watch\?v=[A-Za-z0-9_-]+$/,
    ytshorts: /^https?:\/\/(?:www\.)?youtube\.com\/shorts\/[A-Za-z0-9_-]+\/?$/,
  }
  if (Object.prototype.hasOwnProperty.call(urlRegexMap, type)) {
    const regex = urlRegexMap[type]
    return regex.test(url)
  }
  return false
}

function formatNumber(number) {
  if (number === '' || number === 0 || number === null) {
    return 0
  }
  if (number < 1000) {
    return number
  }
  const suffixes = ['', 'K', 'M', 'B', 'T']
  const suffixNum = Math.floor(('' + number).length / 3)
  if (number >= 100000 && number < 1000000) {
    return Math.floor(number / 1000) + 'K'
  } else {
    let shortValue = parseFloat(
      (suffixNum !== 0
        ? number / Math.pow(1000, suffixNum)
        : number
      ).toPrecision(2),
    )
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1).replace(/\.0+$/, '')
    }
    return shortValue + suffixes[suffixNum]
  }
}

function getTime() {
  //Same as Backend
  const currentTime = new Date().getTime()
  const epochTime = Math.floor(currentTime / 1000)
  return epochTime
}

function epoch2h(epochTime) {
  const date = new Date(epochTime * 1000)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // Month is zero-based, so add 1
  const day = date.getDate()

  // Format the date and time
  const formattedDateTime = `${year}-${month}-${day}`
  return formattedDateTime
}

function timeAgo(date, isUnixTimestamp = false) {
  const now = new Date()
  const timestamp = isUnixTimestamp ? new Date(date * 1000) : new Date(date)
  const seconds = Math.floor((now - timestamp) / 1000)

  if (seconds < 60) {
    return 'Just now'
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60)
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600)
    return `${hours} hour${hours > 1 ? 's' : ''} ago`
  } else if (seconds < 604800) {
    const days = Math.floor(seconds / 86400)
    return `${days} day${days > 1 ? 's' : ''} ago`
  } else {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    return timestamp.toLocaleDateString(undefined, options)
  }
}

function updateLocalStorage(key, value) {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key) // Remove existing key-value pair
  }
  localStorage.setItem(key, value) // Set the updated value
}

function updateObjectById(array, id, updatedObject) {
  return array.map((obj) => {
    if (obj.id === id) {
      return { ...obj, ...updatedObject }
    }
    return obj
  })
}

function openLink(field) {
  const dynamicURL = field
  window.open(dynamicURL, '_blank')
}

function sortObjectByKey(obj) {
  return Object.entries(obj)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by keys
    .reduce((sortedObj, [key, value]) => {
      sortedObj[key] = value
      return sortedObj
    }, {})
}

function mergeAndSortArrays(
  arr1,
  arr2,
  sortKey,
  sortKey2,
  key1,
  key2,
  sortOrder = 'desc',
) {
  // Handle empty arrays
  if (arr1.length === 0 && arr2.length === 0) {
    return []
  } else if (arr1.length === 0) {
    return arr2
      .map((item) => ({ ...item, origin: key2 }))
      .sort((a, b) =>
        compareValues(a[sortKey][sortKey2], b[sortKey][sortKey2], sortOrder),
      )
  } else if (arr2.length === 0) {
    return arr1
      .map((item) => ({ ...item, origin: key1 }))
      .sort((a, b) =>
        compareValues(a[sortKey][sortKey2], b[sortKey][sortKey2], sortOrder),
      )
  }
  const mergedArray = [
    ...arr1.map((item) => ({ ...item, origin: key1 })),
    ...arr2.map((item) => ({ ...item, origin: key2 })),
  ]
  mergedArray.sort((a, b) =>
    compareValues(a[sortKey][sortKey2], b[sortKey][sortKey2], sortOrder),
  )
  return mergedArray
}

function compareValues(value1, value2, sortOrder) {
  if (sortOrder === 'asc') {
    return value1 - value2
  } else if (sortOrder === 'desc') {
    return value2 - value1
  }
  // Default to ascending order if sortOrder is not specified correctly
  return value1 - value2
}

function capitalizeFirstLetter(word) {
  return word[0].toUpperCase() + word.slice(1)
}

function logout() {
  localStorage.clear()
  window.location.href = '/login'
}

module.exports = {
  isEmptyObject,
  convertTo2DArray,
  fixNumberToDecimalPlaces,
  validateURL,
  extractKeysAndValues,
  formatNumber,
  getTime,
  epoch2h,
  timeAgo,
  updateLocalStorage,
  updateObjectById,
  openLink,
  sortObjectByKey,
  mergeAndSortArrays,
  compareValues,
  capitalizeFirstLetter,
  logout,
}
