const env = {
  VUE_APP_API_URL: 'https://api.goflurry.com/api',
  VUE_APP_APID_URL: 'https://apid.goflurry.com/api',
  // VUE_APP_API_URL: 'http://localhost:8083/api',
  // VUE_APP_APID_URL: 'http://localhost:8082/api',
  VUE_APP_GOOGLE_CLIENT_ID:
    '192896314348-oglqhvf84vr063ui8hr459rklvatum71.apps.googleusercontent.com',
  VUE_APP_FIREBASE_KEY:
    'BAa3TRK771O96NKh00G1hw-eUg5xfmcw1mSVH5XltWS1QxNWl5SuT-YI3CA3xVS2f24Lpt_cQ7uMvfxmDs9jWQQ',
}

export default env
