import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import env from '@/env.js'

import VueMultiselect from 'vue-multiselect'
// import { iconsSet as icons } from '@/assets/icons'
import tooltip from '@/components/common/tooltip.js'
import '@/styles/tooltip.css'
import vue3GoogleLogin from 'vue3-google-login'
import Vue3TouchEvents from 'vue3-touch-events'

const app = createApp(App)
app.use(vue3GoogleLogin, {
  clientId: env.VUE_APP_GOOGLE_CLIENT_ID,
})
app.use(Vue3TouchEvents) // adds gesture events (swipe left, right)
app.use(store)
app.use(router)
// app.provide('icons', icons)
app.component('VueMultiselect', VueMultiselect)
app.directive('tooltip', tooltip)

app.mount('#app')

let publicRoute = ['/login', '/register', '/onboard']

router.beforeEach((to, from, next) => {
  // check login
  if (localStorage.getItem('login')) {
    // check verified
    if (localStorage.getItem('verified') === 'true') {
      if (publicRoute.includes(to.path)) {
        //if already logged in
        next({
          path: '/home',
        })
      } else {
        // TODO remove this after adding permission on each page
        next()
      }
    } else {
      // If not verified
      if (to.path !== '/home' && to.path !== '/profile') {
        next({
          path: '/home',
        })
      } else {
        next()
      }
    }
  } else {
    // if logged out
    if (publicRoute.includes(to.path)) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      }) // make sure to always call next()!
    }
  }
})
