import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/Home/Index.vue'),
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/views/Search/Index.vue'),
      },
      {
        path: 'notification',
        name: 'Notification',
        component: () => import('@/views/Notification/Index.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/Profile/Index.vue'),
      },
      {
        path: 'chat/home',
        name: 'Chat-Home',
        component: () => import('@/views/Home/Accept.vue'),
      },
      {
        path: 'chat',
        name: 'Chat',
        component: () => import('@/views/chat/Accept.vue'),
      },
      {
        path: 'chat/invite',
        name: 'Chat Invite',
        component: () => import('@/views/chat/Invite.vue'),
      },
      {
        path: 'chat/accept',
        name: 'Chat Accept',
        component: () => import('@/views/chat/Accept.vue'),
      },
      {
        path: 'chat/:user',
        name: 'Chat Detail',
        component: () => import('@/views/chat/Detail.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/BrandLogin.vue'),
      },
      {
        path: 'onboard',
        name: 'Onboard',
        component: () => import('@/views/pages/Onboard.vue'),
      },
      {
        path: 'notif',
        name: 'Notif',
        component: () => import('@/views/Notif'),
      },
    ],
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: DefaultLayout,
  //   redirect: '/home',
  //   children: [
  //     {
  //       path: 'home',
  //       name: 'Home',
  //       component: () => import('@/views/HomeView.vue'),
  //     },
  //     {
  //       path: '/home/detail/:id',
  //       name: 'Talent Details',
  //       component: () => import('@/views/Home/TalentDetail.vue'),
  //     },
  //     {
  //       path: 'login',
  //       name: 'Login',
  //       component: () => import('@/views/pages/Login'),
  //     },
  //     // {
  //     //   path: 'register',
  //     //   name: 'Register',
  //     //   component: () => import('@/views/pages/Register'),
  //     // },
  //     {
  //       path: 'trending',
  //       name: 'Trending',
  //       component: () => import('@/views/pages/Trending'),
  //     },
  //     {
  //       path: 'trending2',
  //       name: 'Trending2',
  //       component: () => import('@/views/trending/Index')
  //     },
  //     {
  //       path: '/campaign',
  //       name: 'Campaigns',
  //       component: () => import('@/views/Campaigns/Index'),
  //     },
  //     {
  //       path: '/campaign/detail',
  //       name: 'Campaign Detail',
  //       component: () => import('@/views/Campaigns/Detail'),
  //     },
  //     {
  //       path: '/programs',
  //       name: 'Programs',
  //       component: () => import('@/views/programs/Index')
  //     },
  //     {
  //       path: 'programs/detail',
  //       name: 'Programs Detail',
  //       component: () => import('@/views/programs/Detail')
  //     },
  //     {
  //       path: 'creators',
  //       name: 'Creators',
  //       component: () => import('@/views/creators/Index')
  //     }
  //   ],
  // },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      // {
      //   path: '404',
      //   name: 'Page404',
      //   component: () => import('@/views/pages/Page404'),
      // },
      // {
      //   path: '500',
      //   name: 'Page500',
      //   component: () => import('@/views/pages/Page500'),
      // },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
